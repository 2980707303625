// import React from 'react'
// import { GelatoLimitOrderPanel, GelatoLimitOrdersHistoryPanel } from 'soulswap-limit-orders-react'

// export default function LimitOrder() {
//   return (
//     <>
//       <GelatoLimitOrderPanel />
//       <GelatoLimitOrdersHistoryPanel />
//     </>
//   )
// }
import Container from 'components/Container'
import React from 'react'
// import { Feature } from 'enums'
// import HeaderNew from 'features/trade/HeaderNew'
// import NetworkGuard from 'guards/Network'
// import { SwapLayout, SwapLayoutCard } from './SwapLayout'
// import { useActiveWeb3React } from 'services/web3'
// import { useAppDispatch } from 'state/hooks'
// import { useExpertModeManager } from 'state/user/hooks'
import { GelatoLimitOrderPanel, GelatoLimitOrdersHistoryPanel } from 'soulswap-limit-orders-react'


const LimitOrder = () => {
  // const dispatch = useAppDispatch()
  // const { chainId } = useActiveWeb3React()
  // const [isExpertMode] = useExpertModeManager()
  // const { inputCurrency, outputCurrency } = useLimitOrderDerivedCurrencies()
  return (
    <Container>
    <div className="flex flex-col gap-3 p-2 pt-4 rounded-[18px] bg-dark-900 shadow-md shadow-dark-1000">
      <div id="limit-page" className="flex rounded bg-dark-1000 z-1">
        <div className="ml-0 mb-4 sm:ml-0">
          <GelatoLimitOrderPanel />
          <GelatoLimitOrdersHistoryPanel />
        </div>
      </div>
    </div>
    </Container>
  )
}

export default LimitOrder